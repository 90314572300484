import React from 'react'
import './footer.sass'
import Socials from '../../3_molecules/socials/socials'
import Email from '../../3_molecules/email/email'
import Contact from '../../3_molecules/Contact/contact'

const Footer = () => {
    return(
        <section className='footer' id='footer'>
            <div className='footer__group'>
                <div className='footer__socials'>
                    <Socials />
                </div>
                <div className='footer__contact'>
                    <Contact />
                </div>
            </div>
            <div className='footer__email'>
                <Email />
            </div>

            
        </section>
    )
}

export default Footer