import React from "react"
import './email.sass'

const Email = () => {
    return(
        <>
        <div className="emailInfo">
            <p>Wees als eerste op de hoogte van alles bij Forigtail — denk aan events, nieuwe producten en speciale releases. Ja, er komen goede dingen aan!</p>
        </div>
        <div className="email">
            <div className="textShade emailHeader"><h3>join the family</h3></div>
            <form action="#footer">
                <input className="input__field" placeholder="email adress" type="email" name="email" />
                <input value="submit" className="input__submit" type="submit" />
            </form>
        </div>
        </>
    )
}

export default Email