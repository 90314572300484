import React, { useState } from "react"
import './Info.sass'


import img1 from '../../../assets/img/slider/slider1.jpg'
import img2 from '../../../assets/img/slider/slider2.jpg'
import img3 from '../../../assets/img/slider/slider3.jpg'
import img4 from '../../../assets/img/slider/slider1.jpg'

const Info = () => {

    const [toggleState, setToggleState] = useState(1)

    const toggleTab = (index) => {
        setToggleState(index)
    }

    return(
        <section id="info" className="info">
            <h2>Hoe werkt het?</h2>
            <div className="width background">
                <div className="bloc-tabs">
                    <button className={toggleState === 1 ? "tabs tabs--active" : "tabs"} onClick={() => toggleTab(1)}>
                        tab1
                    </button>
                    <button className={toggleState === 2 ? "tabs tabs--active" : "tabs"} onClick={() => toggleTab(2)}>
                        tab2
                    </button>
                    <button className={toggleState === 3 ? "tabs tabs--active" : "tabs"} onClick={() => toggleTab(3)}>
                        tab3
                    </button>
                    <button className={toggleState === 4 ? "tabs tabs--active" : "tabs"} onClick={() => toggleTab(4)}>
                        tab4
                    </button>
                </div>

                <div className="content-tabs">
                    <div className={toggleState === 1 ? "content content--active" : "content"}>
                        <div className="group">
                            <div className="content__h"><h3>algemeen</h3></div>
                            <div className="content__p"><p>Lorem ipsum dolor sit ameonsectetur adipiscing elit, seonsectetur adipiscing elit, seonsectetur adipiscing elit, seonsectetur adipiscing elit, seonsectetur adipiscing elit, seonsectetur adipiscing elit, seonsectetur adipiscing elit, seonsectetur adipiscing elit, set, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p></div>
                        </div>
                        <div className="content__img">
                            <img src={img1}></img>
                        </div>
                    </div>

                    <div className={toggleState === 2 ? "content content--active" : "content"}>
                        <div className="group">
                            <div className="content__h"><h3>wandelpaden</h3></div>
                            <div className="content__p"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p></div>
                        </div>
                        <div className="content__img">
                            <img src={img2}></img>
                        </div>
                    </div>
                    <div className={toggleState === 3 ? "content content--active" : "content"}>
                        <div className="group">
                            <div className="content__h"><h3>kruispunten</h3></div>
                            <div className="content__p"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p></div>
                        </div>
                        <div className="content__img">
                            <img src={img3}></img>
                        </div>
                    </div>
                    <div className={toggleState === 4 ? "content content--active" : "content"}>
                        <div className="group">
                            <div className="content__h"><h3>speelkaarten</h3></div>
                            <div className="content__p"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p></div>
                        </div>
                        <div className="content__img">
                            <img src={img4}></img>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Info