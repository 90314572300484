import React from "react"
import Cta from '../../3_molecules/cta/cta'
import Socials from "../../3_molecules/socials/socials"
import Logo from '../../2_atoms/logo/logo'
import './header.sass'

const Header = () => {
    return(
        <header>
            <div className="container header__container">
                <Logo />
                <div className="main-title spacingTop"><h1>Forgitail</h1></div>
                <h5 className="text-light spacing textShade">Forge your tale!</h5>
                <Cta/>
                <Socials/>
            </div>
        </header>
    )
}

export default Header;