import React, { useRef, useEffect, useState } from "react"
import { motion } from "framer-motion"

import images from "./img-slider-data"
import './img-slider.sass'

const ImgSlider = () => {
    const [width, setWidth] = useState(0)
    const carousel = useRef()

    useEffect(() => {
        setTimeout(() => { 
            setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth)
            console.log(carousel.current.scrollWidth)
            console.log(carousel.current.offsetWidth)
        }, 150)
    }, [])
    return (
        <div className="slider">
            <motion.div ref={carousel} className="carousel" whileTap={{cursor: "grabbing"}}>
                <motion.div  className="innerCarousel" 
                    drag="x" 
                    dragConstraints={{ right: 0, left: -width }}>
                    {images.map((image) => {
                        return(
                            <motion.div className="item" key={image}>
                                <img src={image} alt=""/>
                            </motion.div>
                        )
                    })}
                </motion.div>
            </motion.div>
        </div>
    )
}

export default ImgSlider