import React from "react"
import './pre-order.sass'
import Button from "../../2_atoms/buttons/buttons"

const PreOrder = () => {
    return (
        <div className="preOrder">
            <div className="preOrderCard">
                <h3>---------------------------</h3>
                <div className="buttonPlacer">
                    <Button buttonClass="btn btn-1" buttonText = "kopen"/>
                </div>
                <h5>binnenkort beschikbaar</h5>
                <h3>---------------------------</h3>
            </div>
        </div>
    )
}

export default PreOrder