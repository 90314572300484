import React from "react"
import './card.sass'

const Card = (props) => {
    return(
            <div className="card">
                <div className="card__title"><h3>{props.h3}</h3></div>
                <p className="text__paragraph"><p>{props.p}</p></p>
            </div>
    )
}

export default Card