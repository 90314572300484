import React from "react"
import img from '../../../assets/img/logo.png'
import './logo.sass'

const Logo = (props) => {
    return(
            <div className="logo">
                <img src={img} alt="logo"/>
            </div>
    )
}

export default Logo