import React from "react"
import './socials.sass'
import {ImFacebook2, ImTwitter, ImInstagram} from 'react-icons/im'
import {FaTwitterSquare} from 'react-icons/fa'

const Socials = () => {
    return(
        <div className="socials">
            <a href="https://twitter.com/" target="_blank"><FaTwitterSquare size={'1.5rem'}/></a>
            <a href="https://www.facebook.com/" target="_blank"><ImFacebook2 size={'1.5rem'}/></a>
            <a href="https://www.instagram.com/" target="_blank"><ImInstagram size={'1.5rem'}/></a>
        </div>
    )
}

export default Socials;