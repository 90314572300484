import React from "react"
import './about.sass'
import ImgCardGroup from "../../3_molecules/img-card-group/card-group"
import ImgSlider from "../../3_molecules/img-slider/img-slider"

const About = () => {
    return(
        <section id="about" className="about">
            <div><h2>Wat is Forgitail?</h2></div>
            <div className="width aboutBackground">
                <ImgCardGroup />
                <div className="imgSlider"><ImgSlider/></div>
            </div>
        </section>
    )
}

export default About