import React from 'react'
import './index.sass'
import Header from './components/4_organisms/header/header'
import Nav from './components/4_organisms/nav/nav'
import Home from './components/4_organisms/home/home'
import Footer from './components/4_organisms/footer/footer'
import About from './components/4_organisms/about/about'
import Info from './components/4_organisms/info/info'
import Purchase from './components/4_organisms/purchase/purchase'

const App = () => {
    return(
        <div className='container'>
            <Home />
            <Header />
            <Nav/>
            <About />
            <Info />
            <Purchase />
            <Footer />
        </div>
    )
}

export default App