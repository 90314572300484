import React from 'react'
import PreOrder from '../../3_molecules/purchase/pre-order'

import './purchase.sass'

const Purchase = () => {
  return (
    <section id="purchase" className="purchase">
      <h2>Bestel nu</h2>
      <div className='width placer'>
        <PreOrder />
      </div>
    </section>
    
  )
}

export default Purchase