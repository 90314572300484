import React from 'react'
import './home.sass'
import backgroundVideo from '../../.././assets/video/home-video.mkv'


const Home = () => {
    return(
        <div className='screen'>
            
                <video autoPlay="autoplay" muted>
                    <source src={backgroundVideo} type="video/mp4"/>
                </video>
                
        </div>
    )
}

export default Home