import React from "react"

const Cta = () => {
    return(
        <div className="cta">
                <a className="btn btn-1" href="#purchase">bestel nu</a>
                <a className="btn btn-2" href="#footer">neem contact op</a>    
        </div>
    )
}

export default Cta;