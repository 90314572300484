import React from "react"
import {TiHome, TiInfoLargeOutline} from 'react-icons/ti'
import {BiPurchaseTagAlt, BiGame, BiQuestionMark} from 'react-icons/bi'
import {RiQuestionAnswerFill} from 'react-icons/ri'



import './nav.sass'

const Nav = () => {
    return(
        <nav>
            <a href="#"><TiHome size={'1.5rem'}/></a>
            <a href="#about"><TiInfoLargeOutline size={'1.5rem'}/></a>
            <a href="#info"><BiGame size={'1.5rem'}/></a>
            <a href="#purchase"><BiPurchaseTagAlt size={'1.5rem'}/></a>
        </nav>
    )
}

export default Nav