import React from "react"
import './contact.sass'

const Contact = () => {
    return(
        <div className="contact">
            <div>
                <h6>neem contact op</h6>
                <div className="group1">
                    <ul>
                        <h7>email:</h7>
                        <p>forgitail@gmail.com</p>
                    </ul>
                </div>

                <div className="group2">
                    <ul>
                        <h7>telefoon</h7>
                        <p>0412345678</p>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Contact